<template>
  <el-main>
    <el-form ref="form" :model="addForm" :rules="rules" label-width="135px">
      <div class="title">企业主体信息</div>
      <el-form-item label="选择商户类型：">
        <el-radio-group v-model="addForm.type">
          <el-radio :label="1">个体工商户入驻</el-radio>
          <el-radio :label="2">企业入驻</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="二维码：" prop="sn">
        <el-input v-model="addForm.sn"></el-input>
      </el-form-item>
      <el-form-item label="企业名称：" prop="company_name" v-if="addForm.type == 2">
        <el-input v-model="addForm.company_name"></el-input>
      </el-form-item>
      <el-form-item label="法人姓名：" prop="name">
        <el-input v-model="addForm.name"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证号码：" prop="id_number">
        <el-input v-model="addForm.id_number"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="mobile">
        <el-input v-model="addForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：" prop="email">
        <el-input v-model="addForm.email"></el-input>
      </el-form-item>
      <el-form-item label="账户类型：">
        <el-radio-group v-model="addForm.bank_type" v-if="addForm.type == 1">
          <el-radio :label="1">对公账户</el-radio>
          <el-radio :label="2">对私账户</el-radio>
        </el-radio-group>
        <span v-else>对公账户</span>
      </el-form-item>
      <el-form-item label="开户名称：" prop="bank_name">
        <el-input v-model="addForm.bank_name"></el-input>
      </el-form-item>
      <el-form-item label="银行卡账号：" prop="bank_card">
        <el-input v-model="addForm.bank_card"></el-input>
      </el-form-item>
      <el-form-item label="开户银行：" prop="bank_info">
        <el-input v-model="addForm.bank_info"></el-input>
      </el-form-item>
      <el-form-item label="开户省市地区：" prop="bank_province_id">
        <el-cascader v-model="addForm.bank_province_id" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" clearable filterable :options="cityList"></el-cascader>
      </el-form-item>
      <el-form-item label="合同图片：" prop="contract_picture">
        <ReadyUploadSource
          :showStyle="{
            width: '150px',
            height: '100px',
          }"
          :isMany="true"
          @getSource="val => getImg(val, 1)"
          :changeOrder="val => changeOrder(val, 1)"
          :manyPath="addForm.contract_picture"
          @removeThis="index => addForm.contract_picture.splice(index, 1)"
        ></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="法人身份证正面：" prop="identity_card_front">
        <ReadyUploadSource
          :showStyle="{
            width: '150px',
            height: '100px',
          }"
          @getSource="val => (addForm.identity_card_front = val.path)"
          :path="addForm.identity_card_front"
          @removeThis="() => (addForm.identity_card_front = '')"
        ></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="法人身份证反面：" prop="identity_card_back">
        <ReadyUploadSource
          :showStyle="{
            width: '150px',
            height: '100px',
          }"
          @getSource="val => (addForm.identity_card_back = val.path)"
          :path="addForm.identity_card_back"
          @removeThis="() => (addForm.identity_card_back = '')"
        ></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="营业执照照片：" prop="business_license">
        <ReadyUploadSource
          :showStyle="{
            width: '150px',
            height: '100px',
          }"
          @getSource="val => (addForm.business_license = val.path)"
          :path="addForm.business_license"
          @removeThis="() => (addForm.business_license = '')"
        ></ReadyUploadSource>
      </el-form-item>
      <div class="title">店铺信息</div>
      <el-form-item label="店铺名称：" prop="shop_name">
        <el-input v-model="addForm.shop_name"></el-input>
      </el-form-item>
      <el-form-item label="店铺简介：">
        <el-input v-model="addForm.shop_Introduction" type="textarea" :rows="3"></el-input>
      </el-form-item>
      <el-form-item label="店铺logo：" prop="shop_logo">
        <ReadyUploadSource @getSource="val => (addForm.shop_logo = val.path)" :path="addForm.shop_logo" @removeThis="() => (addForm.shop_logo = '')"></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="店铺banner：">
        <ReadyUploadSource
          :showStyle="{
            width: '150px',
            height: '100px',
          }"
          :isMany="true"
          @getSource="val => getImg(val, 3)"
          :changeOrder="val => changeOrder(val, 3)"
          :manyPath="addForm.shop_banner"
          @removeThis="index => addForm.shop_banner.splice(index, 1)"
        ></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="所属区域：" prop="province_id">
        <el-cascader v-model="addForm.province_id" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" clearable filterable :options="areaList"></el-cascader>
      </el-form-item>
      <el-form-item label="店铺地址：" prop="shop_address">
        <el-input v-model="addForm.shop_address"></el-input>
      </el-form-item>
      <el-form-item label="经营分类：" prop="category_id">
        <el-cascader v-model="addForm.category_id" :options="category" clearable :props="{ value: 'id', label: 'name', children: '_child' }"></el-cascader>
      </el-form-item>
      <el-form-item label="资质证件：">
        <ReadyUploadSource
          :showStyle="{
            width: '150px',
            height: '100px',
          }"
          :isMany="true"
          @getSource="val => getImg(val, 2)"
          :changeOrder="val => changeOrder(val, 2)"
          :manyPath="addForm.qualification"
          @removeThis="index => addForm.qualification.splice(index, 1)"
        ></ReadyUploadSource>
      </el-form-item>
    </el-form>
    <Preservation @preservation="confirmAdd">
      <router-link to="/extension/gatherTweeters/enterpriseSettlement" slot="return" style="margin-right: 10px">
        <el-button>返回</el-button>
      </router-link>
    </Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
  components: {
    ReadyUploadSource,
    Preservation,
  },
  data() {
    return {
      addForm: {
        sn: '',
        type: 1,
        name: '',
        company_name: '',
        id_number: '',
        mobile: '',
        email: '',
        identity_card_front: '',
        identity_card_back: '',
        bank_type: 1,
        bank_name: '',
        bank_card: '',
        bank_info: '',
        bank_province_id: '',
        bank_city_id: '',
        contract_picture: [],
        business_license: '',
        shop_name: '',
        shop_logo: '',
        province_id: '',
        city_id: '',
        area_id: '',
        shop_address: '',
        category_id: '',
        qualification: [],
        shop_Introduction: '',
        shop_banner: [],
      },
      rules: {
        sn: { required: true, message: '请填写二维码', trigger: 'blur' },
        company_name: { required: true, message: '请填写企业名称', trigger: 'blur' },
        name: { required: true, message: '请填写法人姓名', trigger: 'blur' },
        id_number: { required: true, message: '请填写法人身份证号码', trigger: 'blur' },
        mobile: { required: true, message: '请填写联系方式', trigger: 'blur' },
        email: { required: true, message: '请填写邮箱', trigger: 'blur' },
        identity_card_front: { required: true, message: '请添加身份证正面', trigger: 'blur' },
        identity_card_back: { required: true, message: '请添加身份证反面', trigger: 'blur' },
        bank_name: { required: true, message: '请填写开户名称', trigger: 'blur' },
        bank_card: { required: true, message: '请填写银行卡账号', trigger: 'blur' },
        bank_info: { required: true, message: '请填写开户银行', trigger: 'blur' },
        contract_picture: { required: true, type: 'array', message: '请添加合同图片', trigger: 'blur' },
        business_license: { required: true, message: '请添加营业执照', trigger: 'blur' },
        shop_name: { required: true, message: '请填写店铺名称', trigger: 'blur' },
        shop_logo: { required: true, message: '请添加店铺logo', trigger: 'blur' },
        shop_address: { required: true, message: '请填写店铺地址', trigger: 'blur' },
        category_id: { required: true, message: '请选择经营分类', trigger: 'blur' },
        province_id: { required: true, message: '请选择所属区域', trigger: 'blur' },
        bank_province_id: { required: true, message: '请选择开户省市地区', trigger: 'blur' },
      },
      category: [],
      areaList: [],
      cityList: [],
    };
  },
  created() {
    this.getCategory();
    this.getArea();
  },
  methods: {
    getImg(val, type) {
      for (let i in val) {
        type == 1 ? this.addForm.contract_picture.push(val[i].path) : type == 2 ? this.addForm.qualification.push(val[i].path) : this.addForm.shop_banner.push(val[i].path);
      }
    },
    changeOrder(val, type) {
      let arr = [];
      for (let i in val) arr.push(val[i].path);
      type == 1 ? (this.addForm.contract_picture = arr) : type == 2 ? (this.addForm.qualification = arr) : (this.addForm.shop_banner = arr);
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
      this.$axios.get(this.$api.set.area, { tree: 1, level: 1 }).then(res => {
        if (res.code == 0) {
          this.cityList = res.result.list;
        }
      });
    },
    getCategory() {
      this.$axios
        .post(this.$api.serviceProvider.enterprise.enterpriseCateList, {
          name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            this.category = res.result;
          }
        });
    },
    confirmAdd() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let addForm = _.cloneDeep(this.addForm);
          addForm.area_id = addForm.province_id[2];
          addForm.city_id = addForm.province_id[1];
          addForm.province_id = addForm.province_id[0];
          addForm.bank_city_id = addForm.bank_province_id[1];
          addForm.bank_province_id = addForm.bank_province_id[0];
          addForm.category_id = addForm.category_id.join(',');
          if (addForm.type == 2) addForm.bank_type = 1;
          this.$axios.post(this.$api.serviceProvider.enterprise.addEnterprise, addForm).then(res => {
            if (res.code == 0) {
              this.$message.success('添加成功');
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          });
          console.log(addForm);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  padding-bottom: 70px;
  .el-form {
    width: 700px;
  }
  .title {
    font-weight: bold;
    font-size: 16px;
  }
}
</style>